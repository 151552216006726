@font-face {
  font-family: 'icomoon';
  src: url('./assets/Fonts/icomoon.eot?kdbjl0');
  src: url('./assets/Fonts/icomoon.eot?kdbjl0#iefix') format('embedded-opentype'), url('./assets/Fonts/icomoon.ttf?kdbjl0') format('truetype'), url('./assets/Fonts/icomoon.woff?kdbjl0') format('woff'), url('./assets/Fonts/icomoon.svg?kdbjl0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-selected:before {
  content: "\e900";
}

.icon-circle-plus:before {
  content: "\e901";
}

.icon-circle-minus:before {
  content: "\e902";
}

.icon-circle-x:before {
  content: "\e903";
}

.icon-circle-check:before {
  content: "\e904";
}

.icon-information:before {
  content: "\e905";
}

.icon-help:before {
  content: "\e906";
}

.icon-circle-arrow-down-2:before {
  content: "\e907";
}

.icon-radio-uncheck:before {
  content: "\e908";
}

.icon-radio-check:before {
  content: "\e909";
}

.icon-search:before {
  content: "\e90a";
}

.icon-square-plus:before {
  content: "\e90b";
}

.icon-square-minus:before {
  content: "\e90c";
}

.icon-square-x:before {
  content: "\e90d";
}

.icon-check:before {
  content: "\e90e";
}

.icon-x:before {
  content: "\e90f";
}

.icon-no:before {
  content: "\e910";
}

.icon-power:before {
  content: "\e911";
}

.icon-notification:before {
  content: "\e912";
}

.icon-lock:before {
  content: "\e913";
}

.icon-trash:before {
  content: "\e914";
}

.icon-filter:before {
  content: "\e915";
}

.icon-wrench:before {
  content: "\e916";
}

.icon-encryption:before {
  content: "\e917";
}

.icon-contact:before {
  content: "\e918";
}

.icon-network:before {
  content: "\e919";
}

.icon-temperture:before {
  content: "\e91a";
}

.icon-malware:before {
  content: "\e91b";
}

.icon-bulb:before {
  content: "\e91c";
}

.icon-wireless-locked:before {
  content: "\e91d";
}

.icon-reports:before {
  content: "\e91e";
}

.icon-move:before {
  content: "\e91f";
}

.icon-connected:before {
  content: "\e920";
}

.icon-disconnected:before {
  content: "\e921";
}

.icon-protection:before {
  content: "\e922";
}

.icon-protection-2:before {
  content: "\e923";
}

.icon-configuration:before {
  content: "\e924";
}

.icon-save:before {
  content: "\e925";
}

.icon-eject:before {
  content: "\e926";
}

.icon-menu:before {
  content: "\e927";
}

.icon-group:before {
  content: "\e928";
}

.icon-user:before {
  content: "\e929";
}

.icon-admin:before {
  content: "\e92a";
}

.icon-user-minus:before {
  content: "\e92b";
}

.icon-user-plus:before {
  content: "\e92c";
}

.icon-user-fwd:before {
  content: "\e92d";
}

.icon-user-star:before {
  content: "\e92e";
}

.icon-user-contact:before {
  content: "\e92f";
}

.icon-refresh:before {
  content: "\e930";
}

.icon-undo:before {
  content: "\e931";
}

.icon-export:before {
  content: "\e932";
}

.icon-import:before {
  content: "\e933";
}

.icon-file-2:before {
  content: "\e934";
}

.icon-multiple:before {
  content: "\e935";
}

.icon-file-plus:before {
  content: "\e936";
}

.icon-file-x:before {
  content: "\e937";
}

.icon-files:before {
  content: "\e938";
}

.icon-file-2-copy:before {
  content: "\e939";
}

.icon-file-copy:before {
  content: "\e93a";
}

.icon-clipboard:before {
  content: "\e93b";
}

.icon-arrow-double-up:before {
  content: "\e93c";
}

.icon-arrow-double-down:before {
  content: "\e93d";
}

.icon-arrow-double-left:before {
  content: "\e93e";
}

.icon-arrow-double-right:before {
  content: "\e93f";
}

.icon-arrow-single-up-2:before {
  content: "\e940";
}

.icon-arrow-single-down-2:before {
  content: "\e941";
}

.icon-arrow-single-up:before {
  content: "\e942";
}

.icon-arrow-single-down:before {
  content: "\e943";
}

.icon-arrow-single-left:before {
  content: "\e944";
}

.icon-arrow-single-right:before {
  content: "\e945";
}

.icon-email-notification:before {
  content: "\e946";
}

.icon-email-open:before {
  content: "\e947";
}

.icon-email-lock:before {
  content: "\e948";
}

.icon-enduser:before {
  content: "\e949";
}

.icon-console:before {
  content: "\e94a";
}

.icon-reporting:before {
  content: "\e94b";
}

.icon-application:before {
  content: "\e94c";
}

.icon-briefcase:before {
  content: "\e94d";
}

.icon-edit:before {
  content: "\e94e";
}

.icon-report-main:before {
  content: "\e94f";
}

.icon-transfer:before {
  content: "\e950";
}

.icon-chat:before {
  content: "\e951";
}

.icon-download:before {
  content: "\e952";
}

.icon-global-fire:before {
  content: "\e953";
}

.icon-configuration-double:before {
  content: "\e954";
}

.icon-connection-2:before {
  content: "\e955";
}

.icon-arrow-swoop:before {
  content: "\e956";
}

.icon-firewall:before {
  content: "\e957";
}

.icon-caution:before {
  content: "\e958";
}

.icon-status:before {
  content: "\e959";
}

.icon-circle-arrow-right:before {
  content: "\e95a";
}

.icon-circle-arrow-down:before {
  content: "\e95b";
}

.icon-clone-1:before {
  content: "\e95c";
}

.icon-clone-2:before {
  content: "\e95d";
}

.icon-clone:before {
  content: "\e95e";
}

.icon-clone-plus:before {
  content: "\e95f";
}

.icon-checkbox:before {
  content: "\e960";
}

.icon-checkbox-checked:before {
  content: "\e961";
}

.icon-checkbox-small:before {
  content: "\e962";
}

.icon-checkbox-small-checked:before {
  content: "\e963";
}

.icon-duplication:before {
  content: "\e964";
}

.icon-split:before {
  content: "\e965";
}

.icon-mini-arrow-double:before {
  content: "\e966";
}

.icon-mini-arrow-up:before {
  content: "\e967";
}

.icon-mini-arrow-down:before {
  content: "\e968";
}

.icon-mini-arrow-left:before {
  content: "\e969";
}

.icon-mini-arrow-right:before {
  content: "\e96a";
}

.icon-pop3s:before {
  content: "\e96b";
}

.icon-smtp:before {
  content: "\e96c";
}

.icon-3g:before {
  content: "\e96d";
}

.icon-f:before {
  content: "\e96e";
}

.icon-p:before {
  content: "\e96f";
}

.icon-h:before {
  content: "\e970";
}

.icon-s:before {
  content: "\e971";
}

.icon-i:before {
  content: "\e972";
}

.icon-c:before {
  content: "\e973";
}

.icon-u-up:before {
  content: "\e974";
}

.icon-r-up:before {
  content: "\e975";
}

.icon-a-up:before {
  content: "\e976";
}

.icon-u-down:before {
  content: "\e977";
}

.icon-r-down:before {
  content: "\e978";
}

.icon-a-down:before {
  content: "\e979";
}

.icon-on:before {
  content: "\e97a";
}

.icon-off:before {
  content: "\e97b";
}

.icon-livefeed:before {
  content: "\e97c";
}

.icon-email-block:before {
  content: "\e97d";
}

.icon-virus:before {
  content: "\e97e";
}

.icon-file-secure:before {
  content: "\e97f";
}

.icon-secure:before {
  content: "\e980";
}

.icon-checklist:before {
  content: "\e981";
}

.icon-group-3:before {
  content: "\e982";
}

.icon-monitor:before {
  content: "\e983";
}

.icon-calendar:before {
  content: "\e984";
}

.icon-revert:before {
  content: "\e985";
}

.icon-note-alert:before {
  content: "\e986";
}

.icon-invert-circle-up:before {
  content: "\e987";
}

.icon-invert-circle-down:before {
  content: "\e988";
}

.icon-invert-circle-left:before {
  content: "\e989";
}

.icon-invert-circle-right:before {
  content: "\e98a";
}

.icon-dashboard:before {
  content: "\e98b";
}

.icon-shield-check:before {
  content: "\e98c";
}

.icon-gear-check:before {
  content: "\e98d";
}

.icon-package:before {
  content: "\e98e";
}

.icon-heart-beat:before {
  content: "\e98f";
}

.icon-bars:before {
  content: "\e990";
}

.icon-eye:before {
  content: "\e991";
}

.icon-eye-no:before {
  content: "\e992";
}

.icon-circle-download:before {
  content: "\e993";
}

.icon-cards:before {
  content: "\e994";
}

.icon-gauge:before {
  content: "\e995";
}

.icon-stack-bar:before {
  content: "\e996";
}

.icon-earth:before {
  content: "\e997";
}

.icon-harddrive:before {
  content: "\e998";
}

.icon-mobile-2:before {
  content: "\e999";
}

.icon-hardware-stack:before {
  content: "\e99a";
}

.icon-arrow-two-way:before {
  content: "\e99b";
}

.icon-url-2:before {
  content: "\e99c";
}

.icon-web:before {
  content: "\e99d";
}

.icon-airplane:before {
  content: "\e99e";
}

.icon-location:before {
  content: "\e99f";
}

.icon-connection:before {
  content: "\e9a0";
}

.icon-wireless:before {
  content: "\e9a1";
}

.icon-web-block:before {
  content: "\e9a2";
}

.icon-web-allow:before {
  content: "\e9a3";
}

.icon-web-warn:before {
  content: "\e9a4";
}

.icon-ssid-ok:before {
  content: "\e9a5";
}

.icon-ssid-safe:before {
  content: "\e9a6";
}

.icon-ssid-danger:before {
  content: "\e9a7";
}

.icon-no-filter:before {
  content: "\e9a8";
}

.icon-blacklist-remove:before {
  content: "\e9a9";
}

.icon-blacklist-add:before {
  content: "\e9aa";
}

.icon-whitelist-add:before {
  content: "\e9ab";
}

.icon-whitelist-remove:before {
  content: "\e9ac";
}

.icon-heartbeat-tag:before {
  content: "\e9ad";
}

.icon-heartbeat-monitor:before {
  content: "\e9ae";
}

.icon-wireless5:before {
  content: "\e9af";
}

.icon-wireless24:before {
  content: "\e9b0";
}

.icon-bar4:before {
  content: "\e9b1";
}

.icon-bar3:before {
  content: "\e9b2";
}

.icon-bar2:before {
  content: "\e9b3";
}

.icon-bar1:before {
  content: "\e9b4";
}

.icon-categ:before {
  content: "\e9b5";
}

.icon-dyn-c:before {
  content: "\e9b6";
}

.icon-url:before {
  content: "\e9b7";
}

.icon-file:before {
  content: "\e9b8";
}

.icon-acty:before {
  content: "\e9b9";
}

.icon-sgutm:before {
  content: "\e9ba";
}

.icon-xgfirewall:before {
  content: "\e9bb";
}

.icon-secure-wifi:before {
  content: "\e9bc";
}

.icon-web-gateway:before {
  content: "\e9bd";
}

.icon-email-gateway:before {
  content: "\e9be";
}

.icon-puremessage:before {
  content: "\e9bf";
}

.icon-endpoint:before {
  content: "\e9c0";
}

.icon-cloud:before {
  content: "\e9c1";
}

.icon-sophos-clean:before {
  content: "\e9c2";
}

.icon-mobile:before {
  content: "\e9c3";
}

.icon-sandstorm:before {
  content: "\e9c4";
}

.icon-sophos-central:before {
  content: "\e9c5";
}

.icon-cloud2:before {
  content: "\e9c6";
}

.icon-oem:before {
  content: "\e9c7";
}

.icon-safeguard-encryption:before {
  content: "\e9c8";
}

.icon-server-security:before {
  content: "\e9c9";
}

.icon-sophos-home:before {
  content: "\e9ca";
}

.icon-sophos-central-2:before {
  content: "\e9cb";
}

.icon-virtualization-1:before {
  content: "\e9cc";
}

.icon-virtualization:before {
  content: "\e9cd";
}

.icon-sharepoint-security:before {
  content: "\e9ce";
}

.icon-web-application-firewall:before {
  content: "\e9cf";
}

.icon-ico-nac:before {
  content: "\e9d0";
}

.icon-secure-vpn:before {
  content: "\e9d1";
}

.icon-sophos-9:before {
  content: "\e9d2";
}

.icon-sophos-hr:before {
  content: "\e9d3";
}

.icon-nac:before {
  content: "\e9d4";
}

.icon-infinity:before {
  content: "\e9d5";
}

.icon-wireless-app:before {
  content: "\e9d6";
}

.icon-sophos-7:before {
  content: "\e9d7";
}

.icon-iview:before {
  content: "\e9d8";
}

.icon-network-storage-av:before {
  content: "\e9d9";
}

.icon-database:before {
  content: "\e9da";
}

.icon-sandstorm-2:before {
  content: "\e9db";
}

.icon-business:before {
  content: "\e9dc";
}

.icon-sophos-endpoint:before {
  content: "\e9dd";
}

.icon-sophos-10:before {
  content: "\e9de";
}

.icon-tools:before {
  content: "\e9df";
}

.icon-heartbeat:before {
  content: "\e9e0";
}

.icon-sophos-official:before {
  content: "\e9e1";
}

.icon-sophos-s:before {
  content: "\e9e2";
}

.icon-hbplus2:before {
  content: "\e9e3";
}

.icon-hb-2:before {
  content: "\e9e4";
}

.icon-ips-2:before {
  content: "\e9e5";
}

.icon-web-2:before {
  content: "\e9e6";
}

.icon-app-2:before {
  content: "\e9e7";
}

.icon-av-2:before {
  content: "\e9e8";
}

.icon-rt-2:before {
  content: "\e9e9";
}

.icon-nat-2:before {
  content: "\e9ea";
}

.icon-qos-2:before {
  content: "\e9eb";
}

.icon-log-2:before {
  content: "\e9ec";
}

.icon-https-2:before {
  content: "\e9ed";
}

.icon-hbplus1:before {
  content: "\e9ee";
}

.icon-hb-1:before {
  content: "\e9ef";
}

.icon-ips-1:before {
  content: "\e9f0";
}

.icon-web-1:before {
  content: "\e9f1";
}

.icon-app-1:before {
  content: "\e9f2";
}

.icon-av-1:before {
  content: "\e9f3";
}

.icon-rt-1:before {
  content: "\e9f4";
}

.icon-qos-1:before {
  content: "\e9f5";
}

.icon-nat-1:before {
  content: "\e9f6";
}

.icon-log-1:before {
  content: "\e9f7";
}

.icon-https-1:before {
  content: "\e9f8";
}

.icon-user-full:before {
  content: "\e9f9";
}

.icon-users:before {
  content: "\e9fa";
}

.icon-businesses:before {
  content: "\e9fb";
}

.icon-groups:before {
  content: "\e9fc";
}

.icon-move-handle:before {
  content: "\e9fd";
}

.icon-back:before {
  content: "\e9fe";
}

.icon-sfm:before {
  content: "\e9ff";
}

.icon-cont:before {
  content: "\ea00";
}

.icon-plus:before {
  content: "\ea01";
}

.icon-ico_phishing:before {
  content: "\ea02";
}

.icon-ico_pin:before {
  content: "\ea03";
}

.icon-ico_area:before {
  content: "\ea04";
}

.icon-ico_line:before {
  content: "\ea05";
}

.icon-ico_bar:before {
  content: "\ea06";
}

.icon-ico_time:before {
  content: "\ea07";
}

.icon-ico_pie:before {
  content: "\ea08";
}

.icon-ico_landscape:before {
  content: "\ea09";
}

.icon-ico_portrait:before {
  content: "\ea0a";
}

.icon-syncsec:before {
  content: "\ea0b";
}

.icon-column:before {
  content: "\ea0c";
}

.icon-column-add:before {
  content: "\ea0d";
}

.icon-menu-more_menu-more:before {
  content: "\ea0e";
}

.icon-facetime-video:before {
  content: "\ea0f";
}

.icon-chevron-up:before {
  content: "\ea10";
}

.icon-chevron-down:before {
  content: "\ea11";
}

.icon-process:before {
  content: "\ea12";
}

.icon-sandstorm-web:before {
  content: "\ea13";
}

.icon-sandstorm-email:before {
  content: "\ea14";
}
