// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";

body {
  // font-family: SophosSans-Lt, arial, helvetica, sans-serif;
  font-family: "Inter", sans-serif;
  line-height: 1;
  font-size: 14px;
  background: #f7f7f7;
}

*:focus,
a:visited,
a:hover,
input:focus,
a::-moz-focus-inner,
input::-moz-focus-inner,
a,
a:active,
a:focus,
button,
button:focus,
button:active,
.btn,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
  outline: none !important;
  outline: 0 !important;
  border: 0;
  outline-offset: 0px;
  box-sizing: unset;
}

/* color style */
$color-CoolGrey: #878b97;
$color-River: #005bc8;
$color-Texts: #4a4a4a;

@font-face {
  font-family: "SophosSans-Lt";
  src: url("/assets/Fonts/SophosSans-Light.eot"); /* IE9 Compat Modes */
  src: url("/assets/Fonts/SophosSans-Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/Fonts/SophosSans-Light.otf") format("opentype"),
    /* Open Type Font */ url("/assets/Fonts/SophosSans-Light.svg") format("svg"),
    /* Legacy iOS */ url("/assets/Fonts/SophosSans-Light.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/Fonts/SophosSans-Light.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/Fonts/SophosSans-Light.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SophosSans-Md";
  src: url("/assets/Fonts/SophosSans-Medium.eot"); /* IE9 Compat Modes */
  src: url("/assets/Fonts/SophosSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/Fonts/SophosSans-Medium.otf") format("opentype"),
    /* Open Type Font */ url("/assets/Fonts/SophosSans-Medium.svg")
      format("svg"),
    /* Legacy iOS */ url("/assets/Fonts/SophosSans-Medium.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/Fonts/SophosSans-Medium.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/Fonts/SophosSans-Medium.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.p-component {
  // font-family: var(--font-family);
  font-family: "Inter", sans-serif;
  font-feature-settings: var(--font-feature-settings, normal);
  // font-size: 1rem;
  font-size: 1em;
  font-weight: normal;
}

.box-padding .p-lg-4 {
  padding: 15px !important;
}
i.fa {
  font-size: 18px;
}

.container-padding {
  padding: 0 3.5%;
}

.container-fluid {
  width: 98%;
  // font-family: SophosSans-Lt;
  font-family: "Inter", sans-serif;
  border: 1px solid #9b9b9b;
  min-height: 300px;
  float: left;
  margin: 0% 1% 1% 1%;
  padding: 2% 0;
  background: #fff;
}

.content-box {
  padding: 0 3.5%;
  color: #9b9b9b;
  float: left;
  width: 100%;
}

.content-box p {
  line-height: 16px;
}

.box-padding {
  padding: 1%;
}

input,
button,
textarea,
a,
span {
  outline: none;
}

h1 {
  font-size: 26px;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  color: $color-CoolGrey;
}

h2 {
  font-size: 18px;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
}

h3 {
  font-weight: normal;
  font-size: 16px;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  color: $color-CoolGrey;
  font-weight: bold;
}

.right-m15 {
  margin-right: 15px;
}

.padding-tb {
  padding: 15px 15%;
}
/*body .p-datatable .p-datatable-tbody > tr.p-state-highlight .edit{
  color:#fff;
}*/

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #005bc8;
  border-color: #005bc8;
  color: #ffffff;
}

.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-badge {
  vertical-align: middle;
  width: 10px;
}

.p-datatable .p-datatable-thead > tr > th {
  width: 10%;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: transparent;
  color: #005bc8;
}

body .p-datatable .p-editable-column input:focus {
  outline: none !important;
  outline-offset: 0 !important;
}

// .edit {
//   color: #005bb5;
// }

.error {
  color: red !important;
  padding-top: 5px;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

input {
  border-radius: 3px;
  line-height: 34px;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  padding: 0 6px;
  width: 100%;
  border: 1px solid #9b9b9b !important;
  background: #f4f4f4;
}

label {
  color: $color-Texts;
  text-align: left;
  font-size: 12px;
  width: 100%;
  float: left;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  margin-bottom: 5px;
  font-weight: bold;
  margin-top: 10px;
}

.primary-button {
  min-width: 100px;
  // line-height: 40px;
  line-height: 35px;
  border-radius: 3px;
  background: #005bc8;
  border: 1px solid #005bc8;
  color: #fff;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  margin-right: 15px;
}

.primary-button:hover {
  background: #3fa0f6;
  color: #fff;
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
}

.Secondary-button {
  min-width: 100px;
  line-height: 40px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #dadada !important;
  color: $color-Texts;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 14px;
  cursor: pointer;
}

.Secondary-button:hover {
  background: #fff;
  border: 1px solid #878897 !important;
  color: $color-Texts;
}

.cancel-button {
  min-width: 100px;
  line-height: 40px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fff !important;
  color: #005bc8;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-button:disabled {
  cursor:default;
  color: #9b9b9b;
}

.cancel-button:hover:disabled{
  color: #9b9b9b;
}

.cancel-button:hover {
  color: #3fa0f6;
  // text-decoration: underline;
  // font-family: "SophosSans-MD";
  font-family: "Inter", sans-serif;
}

.green-button {
  min-width: 100%;
  height: 40px;
  border-radius: 3px;
  background: #005bc8;
  border: 1px solid #fff !important;
  color: #fff;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 14px;
  cursor: pointer;
}

.green-button:hover {
  color: #fff;
}

.primary-button.disabled {
  background: #9b9b9b;
  cursor: default;
}

/* style checkbox */

.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color-Texts;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #9b9b9b;
}
/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
  border: 1px solid #9b9b9b;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #9b9b9b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid #2196f3;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.grey-bg {
  background: #eeeeee;
}

i.fa.fa-check-circle.green {
  color: #5aab72;
  float: left;
  margin-right: 5%;
}

i.fa.orange {
  color: #e4974a !important;
  float: left;
  margin-right: 5%;
}

i.fa.red {
  color: #bf272f !important;
  float: left;
  margin-right: 5%;
}

i.fa.blue {
  color: #005bc8 !important;
  float: left;
  margin-right: 5%;
}

.right {
  float: right;
  text-align: right;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}

.container-padding .right {
  float: left !important;
  width: 100% !important;
  margin-top: 15px;
}

.apply {
  position: absolute;
  right: 7%;
  padding: 15px 0;
  margin: 0;
}

.left {
  float: left;
}

.search {
  font-size: 18px;
}

.file-box {
  background: #eee;
  padding: 5% 10%;
  float: left;
  width: 100%;
  min-height: 100% !important;
  max-height: 100% !important;
  overflow: auto;
}
/* table grid style */
.p-datatable table {
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
}
/*body .content-box .p-datatable .p-datatable-caption {
  border:0;
}*/

body .p-datatable .p-datatable-caption {
  /*border: 0;*/
  background: #f4f4f4;
  padding: 10px 0;
  /*float: right;*/
  width: 100%;
}

body .p-datatable .p-datatable-thead > tr > th {
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #c8c8c8 !important;
  background: #efefef;
  height: 30px;
  border-top: 1px solid #c8c8c8 !important;
  border-right: 1px solid #c8c8c8 !important;    
  font-size: 14px;
  font-weight: 700;
  color: #2C2D2E;
  padding: 8px 12px 8px 12px;
}

body .p-datatable .p-datatable-tbody > tr {
  // color: $color-River;
  color: #005bc8;
  height: 3.7em;
}

body .p-datatable .p-datatable-tbody > tr > td {
  line-height: 16px;
  font-size: 14px; 
  font-weight: 500; 
  color: #777A7D; 
  padding: 8px 12px 8px 12px;
}

/*body .p-datatable .p-datatable-tbody > tr.p-state-highlight i {
    color: #fff;
  }*/

body .p-datatable .p-datatable-thead > tr > td:first-child {
  border: none !important;
}

body .p-datatable .p-datatable-tbody > tr:hover {
  border: 0 !important;
  background: #b0d9f3 !important;
  color: $color-Texts;
  cursor: pointer;
}

body .p-datatable .p-sortable-column.p-state-highlight {
  background-color: #efefef;
  color: $color-Texts;
}

body .p-datatable .p-sortable-column.p-state-highlight i {
  color: $color-River !important;
}

.tableEdit:hover::before {
  font-family: FontAwesome !important;
  font-weight: 900;
  content: "\f040";
  // color: $color-River;
  color: #005bc8;
  float: right;
  font-size: 16px;
}

.tableEdit:hover {
  content: none;
}

body .p-datatable .p-datatable-tbody > tr:active {
  background: #b0d9f3;
  color: $color-Texts;
  cursor: pointer;
}

body .p-datatable .p-editable-column input {
  font-size: 10px;
  // color: #005bc8;
  // font-family: "SophosSans-MD";
  font-family: "Inter", sans-serif;
  width: 100%;
  padding: 0 6px;
}

body .p-datatable .p-editable-column input:focus {
  outline: 1px solid #ccc;
  outline-offset: 2px;
}

p-editable-column ng-star-inserted p-editing-cell {
  border: 1px solid #ccc;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #005bc8;
  border-color: #005bc8;
  color: #ffffff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  // line-height: 0;
  // padding: 0 1em;
}

.p-tabview .p-tabview-nav li .p-tabview-left-icon {
  color: #1f8d5b;
}
span.p-button-icon-left.pi.pi-plus {
  display: none;
}

body .p-button.p-button-text-icon-left .p-button-text {
  padding: 5px 14px;
}

body .p-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  background-color: #116fbf;
  color: #fff;
}

body .p-dialog .p-dialog-titlebar {
  text-align: left;
  font-weight: bold;
}

body .p-button {
  /*margin: 0;
  color: #808080;
  background-color: #fff;
  border: 2px solid #808080;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.2s;*/
  min-width: 70px;
  height: 26px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #dadada !important;
  color: $color-Texts;
  // font-family: "SophosSans-Lt";
  font-family: "Inter", sans-serif;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
body .p-button:first-child {
  // background-color: #007ad9;
  // border: 1px solid #007ad9;
  // color: #fff;
  color: #005bc8;
}

p-button.savebutton button{
  background-color: #007ad9;
  border: 1px solid #007ad9;
  color: #fff !important;
}

body .p-fileupload-choose:not(.p-state-disabled):hover {
  background-color: #808080;
  color: #ffffff;
  border: 2px solid #808080;
}

body .p-datatable .p-datatable-tbody > tr.p-state-highlight {
  background-color: #ffffff;
  color: $color-River;
}

/*body .p-datatable .p-datatable-tbody > tr.p-state-highlight:hover {
    background-color: $color-River !important;
    color: #ffffff;
  }

  body .p-datatable .p-datatable-tbody > tr.p-state-highlight i.fa.fa-pencil.ng-star-inserted, tableEdit {
    color: #ffffff;
  }*/

body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-state-highlight {
  background-color: #ffffff;
  color: $color-River;
}

/*body .p-datatable .p-sortable-column a, body .p-datatable .p-sortable-column a:hover {
    color: $color-River;
  }*/

.info input {
  display: none;
}

/*p-overlaypanel.pop1 {
  position: absolute;
  top: -210px;
  left: -67px;
}*/
.content-box table.p-datatable-scrollable-body-table {
  margin-bottom: 100px;
}
.p-datatable-scrollable-body {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
/*.p-datatable-virtual-scroller{
  min-height:296px !important;
}*/
/*.content-box .p-tabview-panel.p-widget-content{
  margin-bottom: 20px;
}*/

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.571rem 1rem;
  // padding: 0;
}

p-overlaypanel {
  .ng-trigger {
    top: auto !important;
    right: 3.3% !important;
    left: auto !important;

    .p-overlaypanel-content {
      width: 150px;
      padding: 0;

      p {
        padding: 5px 10px;
        font-size: 12px;

        a {
          color: $color-River;
        }

        :hover {
          color: $color-Texts;
        }
      }
    }
  }
}

hr {
  float: left;
  width: 100%;
  color: #9b9b9b !important;
}

.grid-box {
  float: left;
  width: 100%;
}

.p-button-icon-only .p-button-icon-left,
.p-button-text-icon-left .p-button-icon-left,
.p-button-text-icon-right .p-button-icon-right {
  display: none;
}
/*.p-menubar .p-menubar-root-list > .p-menuitem{
  border-right:1px solid #ccc;
}*/
.p-menubar .p-menubar-root-list > .p-menuitem:last-child {
  border: 0 !important;
}
.p-menubar-root-list
  .p-menuitem
  .active.p-menuitem-link.p-corner-all.ng-star-inserted.p-state-active
  span,
.greylink {
  color: #9b9b9b !important;
}
/*.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.p-tabview-title::before {
  font-family: FontAwesome !important;
  font-weight: 900;
  content: "\f058";
  margin-right: 6px;
  color: #5AAB72;
}*/

/* migrations style */
body .p-tabview .p-tabview-panels {
  /*min-height: 200px;*/
  padding: 0;
  float: left;
  width: 100%;
  border: 0;
}

body .content-box .p-tabview .p-tabview-panels {
  border: 1px solid #c8c8c8;
}
body .p-inputtext {
  padding: 0;
  width: 5em;
  font-size: 10px;
  // color: #005bc8;
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
}

body .progressBar .p-corner-all {
  padding: 0;
  border-radius: 0 !important;
}

.topmenu p-menubar {
  float: right;
}

.content-box .p-tabview.p-tabview-top .p-tabview-nav {
  height: 45px;
}

.content-box .p-tabview.p-tabview-top .p-tabview-nav li {
  line-height: 33.5px;
  padding: 0 2%;
}

body .p-tabview.p-tabview-top .p-tabview-nav {
  height: 45px;
  /* padding: 0 7%; */
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
  margin-top: 2%;
  border: 1px solid #c8c8c8;
}

body .p-tabview.p-tabview-top .p-tabview-nav li {
  border-top: none;
  border-left: none;
  border-right: 1px solid #ccc !important;
  border-bottom: none;
  font-size: 10px;
  color: #1987cb !important;
  /*padding: 0 3% 0 0.5%;*/
  width: 12.12% !important;
  float: left;
  line-height: 25.4px;
  text-align: center;
  background: none;
  cursor: pointer;
}

body .p-tabview.p-tabview-top .p-tabview-nav li:hover {
  background: #ddedf7;
  color: #1987cb;
}
/*body .p-tabview.p-tabview-top .p-tabview-nav li:last-child {
  border-right: none !important;
}*/
body .grid-box .p-tabview.p-tabview-top .p-tabview-nav li:last-child {
  border-right: none !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a,
body .p-tabview.p-tabview-left .p-tabview-nav li a,
body .p-tabview.p-tabview-right .p-tabview-nav li a {
  color: #1987cb;
  padding: 3px;
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover {
  /*border-bottom: 2px solid #fff;*/
  color: #1987cb !important;
  background: #ddedf7 !important;
  border-top: none;
  border-left: none;
  border-right: none;
}
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover
  a,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover
  a,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover
  a,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li:not(.p-state-active):not(.p-state-disabled):hover
  a {
  color: #1987cb !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active:hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active:hover,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active:hover,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active:hover {
  color: #fff !important;
  border-top: none;
  border-left: none;
  background: #1987cb;
  border-right: none;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active {
  color: #fff !important;
  border-top: none;
  border-left: none;
  background: #1987cb;
  border-right: none;
}

body .content-box .p-tabview .p-chkbox {
  float: left;
  margin-right: 4%;
  /*margin-top: 2%;*/
}

body .p-chkbox:last-child {
  float: right;
}
/* fileUpload style*/
.chart {
  width: 100%;
  float: left;
}

p-chart {
  width: 48%;
  float: left;
  margin-bottom: 2%;
  margin-left: 26%;
}

canvas.chartjs-render-monitor {
  text-align: center;
  margin: 0 auto;
}

.percentage {
  font-size: 2.4em;
  color: $color-CoolGrey;
}

.file-upload {
  display: block;
  text-align: center;
  // font-family: Helvetica, Arial, sans-serif;
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

.file-upload .file-select {
  display: block;
  border: 1px solid #9b9b9b;
  color: #34495e;
  // cursor: pointer;
  height: 34px;
  line-height: 34px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
}

.file-upload .file-select .file-select-button {
  background: $color-River;
  padding: 0 10px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  float: right;
  color: #fff;
  cursor: pointer;
}

.no-firewall-selected-fileselectbutton {
  background: lightgray;
  padding: 0 10px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  float: right;
  color: #fff;
}

.file-upload .file-select .file-select-name {
  line-height: 32px;
  /*display: inline-block;*/
  display: flex !important;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  border-color: #0985ce;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  background: #0985ce;
  color: #ffffff;
  float: right;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  float: right;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a;
  float: right;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select input[disabled] {
  cursor: not-allowed;
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
  float: right;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  float: right;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  float: right;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
/* megamenu style*/
.p-menubar-root-list {
  float: right;
  /*border: 1px solid #ccc;*/
  font-size: 14px;
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
  font-weight: 100;
  color: $color-River;
}

body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  background: #fff;
}

body .p-menubar {
  padding: 0;
  background-color: #ffffff;
  border: 0px !important;
}

body .p-menu .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem-link .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
body .p-contextmenu .p-menuitem-link .p-menuitem-text,
body .p-menubar .p-menuitem-link .p-menuitem-text,
body .p-slidemenu .p-menuitem-link .p-menuitem-text {
  // color: $color-River !important;
  color: #333333;
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-text {
  color: #005bc8 !important;
}

// .p-menubar .p-menuitem > .p-menuitem-content:hover {
//   background-color: #005BC8 !important;
// }

// .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text:hover {
//   color: #ffffff !important;
// }

p-menubarsub.p-submenu.ng-star-inserted
  .p-menuitem
  .p-menuitem-link
  .p-menuitem-text {
  color: $color-River !important;
  font-size: 11px;
}

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: 5px 10px;
}
/*body .p-menu .p-submenu-list .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: $color-River !important;
  font-size: 10px;
}*/
/*.p-menuitem-link p-corner-all p-menuitem-link p-corner-all span:hover {
    background-color: #0985ce !important;
  }*/
.p-menubar-submenu {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 6.2em;
}

body .p-menubar .p-submenu-list {
  width: 180px;
  margin-top: 0.5em;
}

li.ng-star-inserted.p-menuitem.p-corner-all.p-menu-parent {
  padding-left: 15px;
}

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  color: $color-River !important;
}

body
  .p-menubar
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
body
  .p-menubar
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon {
  color: $color-River;
}

body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #fff;
}

body .p-menubar .p-submenu .p-menuitem-link:hover,
body .p-menubar .p-submenu .p-menuitem-link:hover,
body .p-tieredmenu .p-menuitem-link:hover,
body .p-contextmenu .p-menuitem-link:hover,
body .p-menubar .p-submenu .p-menuitem-link:hover,
body .p-slidemenu .p-menuitem-link:hover {
  color: $color-River !important;
}

body .p-menubar .p-submenu .p-menuitem-link:hover .p-menuitem-text,
body .p-menubar .p-submenu .p-menuitem-link:hover .p-menuitem-text,
body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
body .p-menubar .p-submenu .p-menuitem-link:hover .p-menuitem-text,
body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
  color: $color-Texts !important;
}

p-menubarsub.p-submenu.ng-star-inserted .p-menuitem .p-menuitem-link:hover {
  color: $color-Texts !important;
  background: #bfe3f6 !important;
}

.p-menubar-horizontal .p-menubar-root-list > .p-menuitem {
  display: inline-block;
  border-right: 1px solid #ccc;
  padding: 0 4px;
}

.p-menubar-horizontal .p-menubar-root-list > .p-menuitem:last-child {
  border-right: 0 !important;
  display: none;
}

body .p-menubar .p-menubar-submenu-header {
}

body .p-menubar .p-menubar-submenu-header:hover {
  background: #0985ce;
  color: #fff;
  cursor: pointer;
}

i.fa.fa-pencil.ng-star-inserted {
  float: right;
  color: #005bc8;
  // color: $color-River;
  /*line-height: 15px;*/
}

.p-menubar .p-submenu-list {
  min-width: 100%;
}

p-menuitem-link p-corner-all ng-star-inserted:hover {
  background: #0985ce !important;
  color: #fff;
}
/* progressbar style */
body .p-progressbar {
  border: 0 none;
  height: 45px;
  background-color: #f7f7f7 !important;
  margin-bottom: 5px;
  border-radius: 30px;
}

body .p-progressbar .p-progressbar-value {
  background-color: #59be6c;
}

.p-dialog .p-dialog-header {
  color: #005bc8;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #005bc8;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  color: #3fa0f6;
}

.p-confirmdialog.p-dialog .p-dialog-content {
  padding: 1em 1em;
  text-align: left;
}

a.p-toast-close-icon.pi.pi-times.ng-tns-c4-13.ng-star-inserted {
  display: none;
}

// .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
//   color: #212121;
//   background-color: #ffffff;
//   padding: 0.3em;
//   border-radius: 50%;
// }

.p-toast .p-toast-message .p-toast-message-content {
  align-items: center;
}

// .p-toast .p-toast-message .p-toast-message-content {
//   padding: 1rem;
//   border-width: 0;
// }

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0.5rem;
}

.p-toast-message.p-shadow.p-toast-message-success.ng-trigger.ng-trigger-messageState {
  border: 1px solid #5aab72;
  background: #fff;
  height: 60px;
}

.p-toast-message.p-shadow.p-toast-message-error.ng-trigger.ng-trigger-messageState {
  border: 1px solid #bf272f;
  background: #fff;
  height: 60px;
}

.p-toast-icon {
  // left: 0;
  // top: 0;
}

.p-toast-message-content {
  // padding: 0;
  // margin: 0;
}

.p-toast-message.p-shadow.p-toast-message-error .p-toast-icon::before {
  position: absolute;
  display: inline-block;
  margin: 0;
  content: "\f057";
  font-size: 1.5em;
  background: red;
  font-family: FontAwesome !important;
  font-weight: 900;
  color: #fff;
  width: 80px;
  height: 100px;
  text-align: center;
  top: 0;
  padding-top: 8px;
}

.p-toast-message.p-shadow.p-toast-message-success .p-toast-icon::before {
  position: absolute;
  display: inline-block;
  margin: 0;
  content: "\f058";
  font-size: 1.5em;
  background: #5aab72;
  font-family: FontAwesome !important;
  font-weight: 900;
  color: #fff;
  width: 80px;
  height: 100px;
  text-align: center;
  top: 0;
  padding-top: 8px;
}

.p-toast-item {
  display: block;
  margin: 0;
  padding: 0;
}

.p-toast .p-toast-icon {
  left: 0;
  top: 0;
}

.p-toast-message-text-content .p-toast-summary {
  margin-left: 3em;
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 18px;
  padding-top: 13px;
}

.p-confirmdialog .p-dialog-content .p-confirmdialog-message {
  font-size: 14px;
  line-height: 20px;
}
.p-confirmdialog .p-dialog-content .p-confirmdialog-icon {
  color: #e4974a;
  margin-bottom: 25px;
  float: left;
}
/* filter style*/
.filter {
  background: $color-Texts;
  float: left;
  width: 100%;
}

.filter .p-dropdown {
  border: 1px solid #ccc;
  border-radius: 3px !important;
  font-size: 11px;
  margin: 0 0 10px 10px;
}

.filter .label-text {
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0 0 10px;
}

.filter label {
  font-size: 12px;
  line-height: 25px !important;
  height: 25px;
}

.no-records {
  font-size: 14px !important;
}
/* login popup style */
body .p-dialog .p-dialog-titlebar {
  text-align: left;
  font-weight: normal;
  border-radius: 5px 5px 0 0;
  background: #fff !important;
  height: 60px;
  font-size: 20px;
  color: $color-CoolGrey;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #ccc;
}

.warning-row-color {
  background-color: #ffdab9 !important;
  color: #f27128 !important;
}

body .p-datatable .p-datatable-tbody > tr.warning-row-color:hover {
  background-color: #ffdab9 !important;
  color: #f27128 !important;
}

.error-row-color {
  background-color: #ffcdd0 !important;
  color: #ba2b2b !important;
}

body .p-datatable .p-datatable-tbody > tr.error-row-color:hover {
  background-color: #ffcdd0 !important;
  color: #ba2b2b !important;
}
body
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-state-highlight):not(.p-state-disabled):hover,
body
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group:not(.p-state-highlight):not(.p-state-disabled):hover {
  color: #fff;
  background-color: #005bb5;
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-state-highlight,
body
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group.p-state-highlight {
  color: #ffffff;
  background-color: #005bb5 !important;
  font-size: 10px;
}

.search-summary {
  width: 50%;
  line-height: 34px;
  float: right;
}

.content-box .search-summary {
  width: 50%;
  line-height: 28px;
  float: right;
}

body .progressBar .p-progressbar {
  border: 0 none;
  height: 8px;
  /*background-color: #ccc;*/
  margin-bottom: 5px;
  border-radius: 3px;
}

body .progressBar .p-progressbar .p-progressbar-value {
  background-color: #59be6c;
}

body .progressBar .p-progressbar .p-progressbar-label {
  display: none !important;
}
/* slider style */
body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #59be6c;
}

body
  .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover
  .p-inputswitch-slider {
  background-color: #59be6c;
}

body
  .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus
  .p-inputswitch-slider {
  background: #59be6c;
}

.p-inputswitch {
  width: 2.5rem;
  height: 1rem;
  display: block;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 0.8rem;
  height: 0.8rem;
  left: 0.25rem;
  margin-top: -0.4rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.progressBar {
  position: absolute;
  z-index: 9;
  left: 1.5%;
  top: 0;
  width: 97%;
  float: left;
}

.p-datatable-scrollable-header {
  overflow: unset;
}

.p-datatable-scrollable-wrapper {
  border: 1px solid #ccc;
}

/*.p-datatable-wrapper.ng-star-inserted {
  overflow-y: scroll;
  height: 300px;
  float: left;
}*/
/*.p-datatable .p-datatable-wrapper .p-datatable-tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 250px;
  width: 100%;
}*/

.p-datatable > .p-datatable-wrapper {
  overflow: unset;
  // max-height: 23em;
}

.p-grid {
  padding: 0;
  margin: 0;
}

.p-grid .p-col-12.p-md-12.p-lg-12 {
  padding: 0;
}

.p-dropdown label.p-dropdown-label {
  cursor: pointer;
  color: $color-River !important;
  font-weight: bold !important;
  font-size: 10px !important;
  // font-family: "SophosSans-Md";
  font-family: "Inter", sans-serif;
  height: 25px !important;
  line-height: 25px !important;
}

.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  // color: $color-River !important;
  color: #005bc8 !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

body .p-dropdown {
  border: 0;
  width: auto !important;
}

.ng-trigger .p-dropdown-items-wrapper {
  width: 100% !important;
}

.p-dropdown-items-wrapper {
}

div.p-dropdown {
  // width: 13em !important;
  width: 100% !important;
  border: 1px solid #ccc !important;
  line-height: 22px;
  padding: 0 5px;
  font-size: 10px;
  color: #005bc8;
}

/*p-overlaypanel.pop1 {
  position: fixed;
}*/

.popover-body {
  background: #fff;
  border: 2px solid #dce4ec;
  min-height: 34px;
  float: right;
  width: 20rem;
  position: absolute;
  padding: 0 5px;
  right: 0;
  box-shadow: 0 7px 8px 0px rgba(0, 0, 0, 0.16);

  label {
    width: 52%;
    float: left;
    padding-left: 12px;
  }

  .input-group {
    // width: 48%;
    float: left;
    font-size: 12px;
    padding-top: 16px;
    cursor: pointer;
    color: #007ad9;
    padding-left: 15px;
  }

  a {
    color: #007ad9 !important;
  }
}
/*.p-datatable-scrollable-header-box {
  margin-right: 1%;
}
.p-datatable-scrollable-header-box {
  margin-right: 1% !important;
}*/
.p-tabview.p-widget-content li p {
  line-height: 2.4;
  margin: 5px 0;
  /*color: #005BC8;*/
}

/* Interfaces style */

.interfaces {
  background: #f4f4f4;
  line-height: 27px;
}

p-celleditor {
  width: 100% !important;
  float: left !important;
}
.interface-dropdwon .p-dropdown .p-dropdown-panel {
  min-width: 100%;
}

// body .p-datatable-tbody .p-dropdown {
//   border: 0;
//   width: 88.5% !important;
// }
.interfaces .p-dropdown {
  width: 20% !important;
  border: 1px solid #ccc !important;
  line-height: 22px;
  padding: 0 5px;
  font-size: 10px;
  color: #005bc8;
}
.interfaces .p-dropdown .p-dropdown-trigger {
  // border-left: 1px solid #ccc;
}
.interfaces i {
  padding-right: 10px;
  line-height: 30px;
  float: left;
}
.ng-trigger.ng-trigger-overlayAnimation.ng-tns-c17-2.p-dropdown-panel.p-widget.p-widget-content.p-corner-all.p-shadow.ng-star-inserted {
  width: 125%;
  float: left;
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-state-highlight,
body
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group.p-state-highlight {
  color: #ffffff;
  background-color: #007ad9;
  font-size: 10px;
}
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  font-size: 10px;
  /*margin-bottom: 5px;*/
}
.displayNone {
  display: none !important;
}
.displayInline {
  display: inline !important;
}
#interface .p-datatable-tbody > tr > td.p-editing-cell p-celleditor > * {
  width: auto !important;
}

.feedback {
  position: fixed;
  right: -70px;
  height: 40px !important;
  top: 24%;
  width: 180px;
  background: #005bc8 !important;
  color: #fff !important;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  font-size: 14px !important;
  z-index: 9;
}

body .p-sidebar .p-sidebar-close {
  color: #005bc8;
  border: 0;
  position: absolute;
  right: 15px;
  top: 0;
}

body .p-sidebar .p-sidebar-close:hover {
  color: #0090dd;
}

p-sidebar .ng-tns-c8-1 {
  height: 440px !important;
  margin-top: 6%;
  border-top: 10px solid #005bc8;
}
.feedback-box {
  .feedback2 {
    position: absolute;
    right: 208px;
    height: 40px !important;
    top: 14%;
    width: 180px;
    background: #005bc8 !important;
    color: #fff !important;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    font-size: 14px !important;
    border: 1px solid #005bc8;
    padding: 8px 40px;
    z-index: 8;
  }

  input.input {
    width: 46%;
    float: left;
    margin-right: 4%;
    line-height: 30px;
    font-size: 12px;
  }

  h4 {
    color: #9b9b9b;
    font-size: 12px;
  }

  .primary-button {
    min-width: 60px;
    line-height: 30px;
    font-size: 12px;
    margin-right: 6px;
    float: left;
  }

  .Secondary-button {
    min-width: 60px;
    line-height: 30px;
    font-size: 12px;
    margin-right: 6px;
    float: left;
  }

  .img-boxclick {
    padding: 4px;
    border: 1px solid #007ad9;
    margin-right: 5%;
    cursor: pointer;
  }

  .img-box {
    padding: 4px;
    border: 1px solid #eee;
    margin-right: 5%;
    cursor: pointer;
  }

  textarea {
    border: 1px solid #9b9b9b !important;
    width: 97%;
    float: left;
    height: 70px;
    margin-bottom: 5%;
  }
}
/*-------------------- IPAD ----------------------------*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .p-md-4 {
    padding: 10px !important;
  }

  .percentage {
    right: 46.2%;
    margin-top: 10%;
    font-size: 1.5em;
  }

  .p-menubar-root-list {
    padding-top: 0px;
  }

  body .p-tabview.p-tabview-top .p-tabview-nav li {
    margin: 0;
    font-size: 9px;
    width: 100%;
    padding: 3px 10px;
    line-height: 12px;
    width: 16.4% !important;
  }

  .icon i.fa {
    width: 100%;
  }

  .p-tabview.p-widget-content li p {
    width: 100% !important;
    float: left !important;
  }

  body .p-tabview.p-tabview-top .p-tabview-nav {
    height: auto;
  }

  li.ng-star-inserted.p-menuitem.p-corner-all.p-menu-parent {
    padding: 0;
  }

  .logo {
    .logo-icon {
      font-size: 30px !important;
    }

    .logo-text {
      font-size: 17px !important;
    }
  }

  body .p-datatable .p-datatable-thead > tr > th {
    font-size: 9px;
  }

  i.fa.fa-pencil.ng-star-inserted {
    line-height: 1;
    font-size: 15px;
  }

  .search-summary {
    width: 100% !important;
  }

  .container-padding .right {
    width: 100% !important;
  }

  i.fa {
    padding-right: 0px !important;
    line-height: 18px !important;
    font-size: 17px !important;
  }

  .popover-body {
    width: 45%;
  }

  .p-menubar-root-list {
    font-size: 11px;
  }

  .grid-box {
    .p-grid {
      .migration-grid-header li {
        span {
          width: 34% !important;
        }

        span.icon {
          width: 30% !important;
        }
      }
    }
  }

  body .p-widget-content p {
    line-height: 2;
  }
  /*.p-datatable-scrollable-header-box {
    margin-right: 0 !important;
  }*/
}
/*-------------------- MOBILE ----------------------------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .p-col-12 {
    padding: 10px !important;
  }

  .head-search {
    width: 95% !important;
  }

  .search {
    left: 60% !important;
    top: 18px !important;
  }

  .footer {
    margin-bottom: 15px;
  }

  .p-datatable table {
    overflow: auto;
    display: block;
  }

  .drop-column i.fa {
    font-size: 160px !important;
  }

  i.fa {
    font-size: 18px;
  }

  tbody.p-datatable-tbody {
    width: 100%;
    float: left;
  }

  body .p-datatable .p-datatable-tbody > tr {
    border: 1px solid #ccc;
    float: left;
    width: 100%;
  }

  thead.p-datatable-thead {
    width: 100%;
    float: left;
  }

  tr.ng-star-inserted {
    width: 100%;
    float: left;
  }

  tr.ng-star-inserted td {
    width: 100%;
    float: left;
  }

  p-chart {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .percentage {
    right: 44% !important;
    margin-top: 22% !important;
    font-size: 1em;
  }

  body .p-tabview.p-tabview-top .p-tabview-nav li {
    border-bottom: 2px solid #9b9b9b !important;
    float: left !important;
    margin: 0;
    width: 33.2%;
    font-size: 9px;
    padding: 0;
    height: 42px;
    margin-bottom: 3% !important;
  }

  body .grid-box .p-tabview.p-tabview-top .p-tabview-nav li:last-child {
    border-right: 2px solid #9b9b9b !important;
  }

  .logo {
    .logo-icon {
      font-size: 25px !important;
    }

    .logo-text {
      font-size: 15px !important;
    }
  }

  .topmenu {
    margin-right: 0 !important;
  }

  li.ng-star-inserted.p-menuitem.p-corner-all.p-menu-parent {
    padding-left: 0;
  }

  /*p-overlaypanel .pop1 {
    position: absolute;
    top: -282px;
    left: -21px;
  }*/

  .cancel-button {
    min-width: 0 !important;
  }

  .primary-button {
    margin-right: 0 !important;
  }

  .container-padding .right {
    width: 100% !important;
  }

  .apply {
    margin: 15% 0 0 0;
    right: 8%;
  }

  body .p-tabview.p-tabview-top .p-tabview-nav {
    height: 170px;
    float: left;
    padding: 0;
    width: 100%;
  }

  body .content-box .p-tabview.p-tabview-top .p-tabview-nav {
    height: 45px;
  }

  .search-summary {
    font-size: 10px !important;
    min-width: 80px;
  }

  .popover-body {
    top: 1.5%;
    width: 80%;
  }
}
body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 5px 8px;
}
/*.grid-box {
  .p-grid {
    .migration-grid-header {
      .p-tabview-nav {
        border: 0px !important;
      }

      .clearfix {
        clear: both;
      }
      .p-corner-top {
        border: 0px !important;
        padding: 0px !important;

        a {
          padding: 0px !important;
          width: 100%;
        }
      }

      .p-dialog .p-corner-top {
        padding: 1em !important;

        a {
          padding: 0px !important;
          width: 5%;
        }
      }
    }

    .migration-grid-header li {
      text-align: center;

      span {
        float: left;
        width: 43%;
        background: url("/assets/images/dot.gif") repeat-x;
        background-position: -4px 8px;
      }

      span.icon {
        width: 12%;
        background: none;

        i {
          padding: 1px 1px 0 0px;
          width: 21px;
          margin: auto;
          border: 1px solid #005bb5;
          -webkit-border-radius: 13px;
          border-radius: 13px;
          -moz-border-radius: 13px;
          -khtml-border-radius: 13px;
        }
      }

      .icon:before {
        background: #fff;
      }
    }

    .popover-body label, .popover-body a, .popover-body p {
      text-transform: capitalize;
    }


    .migration-grid-header li:first-child span:first-child, .migration-grid-header li:last-child span.last-child {
      background: none;
    }

    .revert-change i {
      color: #fff;
      font-size: 13px;
      padding-right: 4px;
      float: left;
    }
  }

  .primary-button {
    margin-right: 0px !important;
  }
}*/
@media only screen and (max-device-width: 1024px) and (min-device-width: 768px) {
  .migration-grid-header {
    .p-tabview.p-tabview-top .p-tabview-nav li {
      width: auto;
      padding: 3px 9px;
    }
  }
}

@media only screen and (max-device-width: 1800px) and (min-device-width: 1400px) {
  .file-box {
    min-height: 360px;
    max-height: 360px;
  }
}

@media screen and (max-width: 960px) {
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 190px;
    margin-top: 0.5em;
    left: -11em;
  }
}
